<template lang="pug">
.main-wrapper
    Dialog(header='Remover Banner' :visible.sync='dialog.apagar' :modal='true')
        p Deseja remover o Banner?
        .ta-center.my-4
            Button.p-button-danger(label='Remover' @click='remove()')

    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Banners</b>

    .ta-right.my-2
            Button.ml-2(label='Adicionar' icon="jam jam-plus" @click="$router.push(`/Banners/salvar`)")

    ProgressBar(v-if="waiting || waitingApagar" mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        Panel.datatable(header='Banners')
            DataTable(:value="list")
                Column(headerStyle='width: 45%; font-weight: bold' field='title' header='Título')

                Column(headerStyle='width: 45%; font-weight: bold' field='subtitle' header='Subtítulo')

                Column(headerStyle='width: 10%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="edit(props.data.id)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar(props.data.id)"
                                )
</template>

<style lang="scss" scoped>
.dialogApagar {
    .p-progress-spinner {
        width: 29px;
        height: auto;
    }
}
</style>

<script>
import ProgressBar from "primevue/progressbar";
import Dialog from 'primevue/dialog'
import DataView from 'primevue/dataview'
import Panel from 'primevue/panel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'
import Banners from "../../middleware/controllers/Banners"
import NovaAPI from "../../middleware/controllers/NovaAPI"

export default {
    name: "Banners",
    components: { ProgressBar, Dialog, DataView, Panel, DataTable, Column, Button, Tooltip },
    directives: { tooltip: Tooltip },
    data() {
        return {
            waiting: false,
            waitingApagar: false,
            dialog: {
                apagar: false
            },
            idASerApagado: '',
            list: []
        };
    },
    created() {
        this.waiting = true

        NovaAPI.login().then((result) => {
            this.tokenAPI = result;
        })
        .then(() => {
            this.getList();
            this.waiting = false;
        })

    },
    methods: {
        getList() {
            Banners.get(this.tokenAPI).then(response => {
                this.list = response.rows
            })
        },
        dialogApagar(id) {
            this.idASerApagado = id
            this.dialog.apagar = true
        } ,
        remove() {
            Banners.remove(this.idASerApagado, this.tokenAPI).then(response => {
                this.dialog.apagar = false
                this.getList()
                if ([200,201,204].includes(response.status)) {
                    this.$toast.success("banner removido.")
                    response.data.cd_estado = this.model.cd_estado
                    this.loadModelParams(response.data)
                } else if (response.data.detail) {
                    if (typeof response.data.detail == 'string')
                        this.$toast.error(response.data.detail, { duration: 3000 })
                    else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                }
            })
        },
        edit(id) {
            this.$router.push(`/banners/salvar/${id}/`)
        }
    },

};
</script>
